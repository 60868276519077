<script>
import axios from "axios";

import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required, email } from "vuelidate/lib/validators";
let user = JSON.parse(localStorage.getItem("user"));

/**
 * Register component
 */
export default {
  page: {
    title: "Thêm tài khoản nhân viên",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Thêm nhân viên",
      items: [
        {
          text: "Trang Chủ",
          href: "/",
        },
        {
          text: "Thêm nhân viên",
          active: true,
        },
      ],

      user: {
        team: null,
        username: null,
        email: null,
        password: null,
        price: null,
        viewPrice: true,
        ip: null,
        domain: null,
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      scriptSuccess: null,
    };
  },
  validations: {
    user: {
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      var seft = this;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        axios({
          url: process.env.VUE_APP_SERVER_URL + "/api/user/add",
          method: "post",
          headers: {
            authorization: user.token,
          },

          data: {
            username: this.user.username,
            email: this.user.email,
            password: this.user.password,
          },
        })
          .then(({ data }) => {
            this.tryingToRegister = false;
            this.isRegisterError = false;
            this.registerSuccess = true;

            this.$toasted.success("Thêm tài khoản thành công");
            if (data.script) {
              this.scriptSuccess = data.script;
              window.scrollTo(0, 0);
            } else {
              this.scriptSuccess = null;
            }
            return res;
          })
          .catch((error) => {
            this.tryingToRegister = false;
            this.regError =
              error && error.response && error.response.data
                ? error.response.data
                : "";
            this.$toasted.error(error.response.data);
            this.isRegisterError = true;
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     // console.log(error.response.headers);
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-8 col-xl-8">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Thêm tài khoản nhân viên mới</h5>
                  <p>Bạn cần nhập đầy đủ thông tin theo biểu mẫu.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>

          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="@/assets/images/logo.svg"
                      alt
                      class="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </router-link>
            </div>

            <b-alert
              v-if="registerSuccess"
              v-model="registerSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >Thêm nhân viên <b>{{ user.username }}</b> thành công vào
              <b>{{ user.domain }}</b>
            </b-alert>

            <b-alert
              v-model="isRegisterError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ regError }}</b-alert
            >

            <b-form class="p-2" @submit.prevent="tryToRegisterIn">
              <b-form-group
                class="mb-3"
                id="email-username"
                label="Tên hiển thị"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="user.username"
                  type="text"
                  placeholder="Henry"
                  :class="{
                    'is-invalid': submitted && $v.user.username.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.user.username.required"
                  class="invalid-feedback"
                >
                  Tên tài khoản là được yêu cầu
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="account-email"
                label="Email đăng nhập"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="user.email"
                  type="email"
                  placeholder="user@domain.com"
                  :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                ></b-form-input>
                <div
                  v-if="submitted && $v.user.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.user.email.required">Email là bắt buộc.</span>
                  <span v-if="!$v.user.email.email">Làm ơn nhập email.</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="account-password"
                label="Mật khẩu đăng nhập"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="user.password"
                  type="text"
                  placeholder="Mật khẩu tài khoản user"
                  :class="{
                    'is-invalid': submitted && $v.user.password.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.user.password.required"
                  class="invalid-feedback"
                >
                  mật khẩu là bắt buộc
                </div>
              </b-form-group>

              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Tạo tài khoản</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>